import React, { useState } from "react"
import axios from 'axios';

import SEO from "../components/SEO/SEO"
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import PageHeader from '../components/Header/PageHeader'

const ForgottenPasswordPage = ({ location }) => {

	const [ identifier, setIdentifier ] = useState('')
	const [ showReset, setShowReset ] = useState( true )

	const handleSubmit = async ( event ) => {
		event.preventDefault()
		await axios
		.post( '/api/auth/forgot-password', {
			email: identifier,
		})
		.then(response => {
			// Handle success.
			setShowReset( false )
		})
		.catch(error => {
			//should we throw an error if the user doesnt exist?
			// Handle error.
			console.log('An error occurred:', error.response);
		});
	}

	return (
		<>
			<SEO title="Forgotten Password" />
			<Breadcrumb pathname={ location.pathname } />

			<div className="wrapper">
				<PageHeader title="Forgotten Password" />
				<div className="login">
					<div className="login__container">
						{ showReset ? ( 
							<form className="login__form form" onSubmit={ handleSubmit }>
								<p className="login__form-info">Type in your email address below and we'll send you an email with a link to create a new password.</p>
								<div className="form__input-container">
									<label className="form__label" htmlFor="email">
										Email
									</label>
									<input
										onChange={ e => { setIdentifier(e.target.value)	}}
										value={ identifier }
										className="form__input" id="email" type="email" placeholder="Email" name="email" required/>
								</div>
								<button type="submit" className="form__submit">
									Reset Password
								</button>
							</form> 
						) : (
							<>
								<h2> Reset Password Link has been sent. </h2>
								<p> We've sent a reset password email to { identifier }</p>
							</>
						)
						}
						
					</div>
				</div>
			</div>
		</>
	)
}

export default ForgottenPasswordPage;